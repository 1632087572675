<template>
  <v-container fluid>
    <contract-title :title="options.title.main" class="mb-2" />
    <reference-table
              v-if="headers && items"
              :headers="headers"
              :items="items"
            />
  </v-container>
</template>

<script>
import { Vue } from "vue-property-decorator";
import { get } from "vuex-pathify";
import { mapActions } from "vuex";
import axios from "axios";

export default Vue.extend({
  name: "CustomerItems",
  components: {
    ReferenceTable: () => import("@/components/reference/ReferenceTable.vue"),
    ContractTitle: () => import("@/components/reference/ReferenceTitle.vue"),
  },
  props: {
    title: {
      type: String
    },
    path: {
      type: String
    }
  },
  computed: {
    headers() {
      return [
        // { text: "ID", value: "id"},
        { text: "Регистрационный номер", value: "registrationNumber"},
        { text: "Категория", value: "contractCategory.nameRu"},
        { text: "Тип", value: "contractSubsoilType.nameRu"},
        { text: "Вид сырья", value: "contractMineralGroup.nameRu"},
        { text: "Дата регистрации", value: "registrationDate" },
        { text: "Дата окончания", value: "expirationDate"},
        { text: "Статус", value: "status.nameRu" },
      ]
    },
    statusList: get("reference/getStatus"),
    options: get("reference/optionsContract"),
  },
  data() {
    return {
      items: [],
      organizationList: [],
    };
  },
  methods: {
    ...mapActions({
      fetchStatusReference: "reference/fetchStatus"
    }),
    async fetchContractsByOrganizationId(organizationId) {
      try {
        const response = await axios.get(
          `/api/Reference/Contract/${organizationId}`
        );
        this.loading = false;
        return response.data;
      } catch (error) {
        console.error("Failed to fetch contracts:", error);
        return [];
      }
    },
    async fetchContracts() {
      const organizationId = localStorage.getItem("organizationId");
      if (!organizationId) {
        console.error("Organization ID not found in localStorage.");
        return;
      } 
      try {
        const data = await this.fetchContractsByOrganizationId(
          organizationId
        );
        this.items = data.map((element) => {
          const organization = this.organizationList.find(
            (current) => current.id === element.organizationId
          );
          if (organization) {
            element.organizationName = organization.nameRu;
          } else {
            element.organizationName = "Неизвестная организация";
          }
          return element;
        });
      } catch (error) {
        console.error("Failed to fetch contracts:", error);
      }
    },
    initialize() {
      this.fetchContracts();
    },
  },
  mounted() {},
  created() {
  this.initialize();
},

});
</script>
